
import Vue from 'vue';
import { deferredPrompt, onShowPWAPrompt } from '@/util-functions/initialization-utils';
import gc1 from '@/assets/images/get-app-images/282/gc1-min.jpg';
import gc2 from '@/assets/images/get-app-images/282/gc2-min.jpg';
import ff1 from '@/assets/images/get-app-images/282/ff1-min.jpg';
import ff2 from '@/assets/images/get-app-images/282/ff2-min.jpg';
import brave1 from '@/assets/images/get-app-images/282/brave1-min.jpg';
import brave2 from '@/assets/images/get-app-images/282/brave2-min.jpg';
import edge1 from '@/assets/images/get-app-images/282/edge1-min.jpg';
import edge2 from '@/assets/images/get-app-images/282/edge2-min.jpg';
import ios1 from '@/assets/images/get-app-images/282/ios1-min.jpg';
import ios2 from '@/assets/images/get-app-images/282/ios2-min.jpg';
import ios3 from '@/assets/images/get-app-images/282/ios3-min.jpg';
import desktopScreen from '@/assets/images/get-app-images/282/desktop-min.jpg';
import { isiOS } from '@/util-functions/misc-utils';

enum browsers {
  chrome,
  unknown,
  firefox,
  edge,
  safari,
  brave,
}

export default Vue.extend({
  data(): {
    gc1: any;
    gc2: any;
    ff1: any;
    ff2: any;
    brave1: any;
    brave2: any;
    edge1: any;
    edge2: any;
    ios1: any;
    ios2: any;
    ios3: any;
    desktopScreen: any;
    browsers: typeof browsers;
    deferredPrompt: BeforeInstallPromptEvent | null;
  } {
    return {
      gc1,
      gc2,
      ff1,
      ff2,
      brave1,
      brave2,
      edge1,
      edge2,
      ios1,
      ios2,
      ios3,
      desktopScreen,
      browsers,
      deferredPrompt: deferredPrompt,
    };
  },
  computed: {
    currBrowser(): browsers {
      const ua: string = navigator.userAgent.toLowerCase();
      if (isiOS() || (ua.includes('safari') && !ua.includes('chrome'))) {
        return this.browsers.safari;
      } else if (ua.includes('chrome') && !ua.includes('chromium')) {
        return this.browsers.chrome;
      } else if (ua.includes('firefox')) {
        return this.browsers.firefox;
      } else if (ua.includes('edge')) {
        return this.browsers.edge;
      } else if (ua.includes('brave')) {
        return this.browsers.brave;
      }
      return this.browsers.unknown;
    },
  },
  methods: {
    onShowPWAPrompt() {
      onShowPWAPrompt();
      this.deferredPrompt = null;
    },
    getScreen1() {
      switch (this.currBrowser) {
        case browsers.chrome:
        case browsers.unknown:
          return this.gc1;
        case browsers.firefox:
          return this.ff1;
        case browsers.edge:
          return this.edge1;
        case browsers.safari:
          return this.ios1;
        case browsers.brave:
          return this.brave1;
      }
    },
    getScreen2() {
      switch (this.currBrowser) {
        case browsers.chrome:
        case browsers.unknown:
          return this.gc2;
        case browsers.firefox:
          return this.ff2;
        case browsers.edge:
          return this.edge2;
        case browsers.safari:
          return this.ios2;
        case browsers.brave:
          return this.brave2;
      }
    },
    getDesktopScreen() {
      switch (this.currBrowser) {
        case browsers.safari:
          return this.ios3;
      }
      return this.desktopScreen;
    },
  },
});
